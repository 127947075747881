import axios from "axios";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { userState } from "../../Atoms/auth/UserAuth";
import { API_URL } from "../../config";
// import language from '../../helpers/language';
import { useToastHelper } from "@/helpers/helperFunctions";
import {
  Trading_Experience,
  cn,
  countries,
  countries_iso2,
  countryData,
  University as Universities,
  yos,
} from "@/lib/utils";
import { ChevronsUpDown, Eye, EyeOff, Loader2 } from "lucide-react";
import { NextPage } from "next";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { BsArrowRight } from "react-icons/bs";
import { LuCheck, LuLoader } from "react-icons/lu";
import OTPInput from "react-otp-input";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { ScrollArea } from "../ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone_number: string;
  country: string;
  trading: string;
  university: string;
  degree: string;
  yos: string;
}

const CreateCard: NextPage = (props: any) => {
  const { toast } = useToastHelper();
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation("common");
  const [userStatea, setUserState] = useRecoilState(userState);
  const setFormState = useSetRecoilState(authFormState);
  const [otpLoading, setOtpLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>("United Kingdom");
  const [universityValue, setUniversityValue] = useState<string>("");
  const [resendOtpShow, setResendOtpShow] = useState(false);
  const [countryCode, setCountryCode] = useState<any>(
    countryData?.find((framework) => framework.name == value)?.dial_code || ""
  );
  const [open, setOpen] = useState(false);
  const [openUniversity, setOpenUniversity] = useState(false);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const togglePasswordVisibility = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  const sendOtp = async () => {
    let url = new URL("https://hub2.trylevel2.com/send_sms_otp");
    url.searchParams.append(
      "ph_number",
      countryCode + form.getValues("phone_number")
    );
    url.searchParams.append("email", form.getValues("email"));
    const response = await axios.post(url.toString());
    if (response?.data?.message) {
      setShow(true);
      toast.success(response.data.message);
    } else toast.error("Error sending OTP.");
  };
  const validSmsOtp = async (otp: any) => {
    setOtpLoading(true);
    let url = new URL("https://hub2.trylevel2.com/validate_sms_otp");
    url.searchParams.append("otp", otp);
    url.searchParams.append("email", form.getValues("email"));
    const res = await axios.post(url.toString());
    if (res?.data) {
      // check valid otp
      setShow(false);
      toast.success(res?.data?.message);
      try {
        setLoading(true);
        const response = await axios.post(
          `https://hub2.trylevel2.com/register_user?auth=${res?.data.auth}`,
          {
            email: form.getValues("email"),
            password: form.getValues("password"),
            first_name: form.getValues("firstName"),
            last_name: form.getValues("lastName"),
            country: form.getValues("country"),
            trading_experience: form.getValues("trading"),
            phone_number: countryCode + form.getValues("phone_number"),
            trading_challenge: "app.trylevel2.com",
            source: "trading_challenge_reg_form",
            university: form.getValues("university"),
            degree: form.getValues("degree"),
            yos: form.getValues("yos"),
          }
        );
        if (response) {
          // if (!res) {
          //   setLoading(false);
          //   toast.error(response.data.data.message);
          // } else {
          router.push(response.data.redirect_url);
          toast.success(
            "Registration successful - Please wait to be redirected to Level2"
          );
          // }
        }
        setLoading(false);
      } catch (error: any) {
        setOtpLoading(false);
        setLoading(false);
        toast.error(error.response.data.detail);
      }
    } else {
      toast.error(res?.data?.detail);
    }
  };
  const handleSubmitForm: SubmitHandler<IFormValues> = async (formData) => {
    sendOtp();
  };
  const setAuthHeader = (token: string) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const countrySelectOpt = countries.map((data: any) => {
    return { value: data, label: data };
  });

  const filteredData = countryData.filter((item) =>
    countries_iso2.includes(item.code.toLowerCase())
  );
  useEffect(() => {
    // const userInfo = JSON.parse(localStorage.getItem("user-info")!);
    // const userAuthenticated = localStorage.getItem("userAuthenticated");
    // if (userInfo?.user_id && userAuthenticated === "true") {
    //   setLoading(true);
    //   // check if session is valid
    //   var url = new URL("verify_session", API_URL);
    //   axios
    //     .post(url.toString(), {}, setAuthHeader(userInfo?.token))
    //     .then((res) => {
    //       if (res.statusText == "OK") {
    //         router.query.locale = userInfo?.preference.language || "en";
    //         if (router.query.redirect) {
    //           router.push(router?.query?.redirect?.toString());
    //           return () => {};
    //         }
    //         // setLoading(false);
    //         //if (userInfo?.login_flow_new_user == "1") {
    //         // router.push('/welcome');
    //         // return false;
    //         //router.push(`/on-boarding`);
    //         //  router.push(`/explore?locale=${userInfo?.language}`);
    //         // } else {
    //         router.push(
    //           `/build?locale=${
    //             userInfo?.language || router.query.locale || "en"
    //           }`
    //         );
    //       }
    //     })
    //     .catch((e) => {
    //       localStorage.clear();
    //       signOut({ redirect: false });
    //       setLoading(false);
    //       return;
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    //   // }
    // }
  }, [session, router]);

  if (session) {
    //router.push('/build')
    // return (
    //   <div className="">
    //     Welcome user<br />
    //     <button onClick={() => signOut()}>Sign out</button>
    //   </div>
    // );
  }

  const form = useForm<IFormValues>();

  const submit = () => {
    setOtp("");
    validSmsOtp(otp);
  };
  const resendTextAppear = () => {
    const interval = setInterval(() => setResendOtpShow(true), 60000);
    return () => {
      clearInterval(interval);
    };
  };
  const resend = () => {
    setResendOtpShow(false);
    sendOtp();
    resendTextAppear();
  };
  return (
    <>
      <Dialog open={show} onOpenChange={(open) => setShow(!show)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Enter verification code</DialogTitle>
          </DialogHeader>
          <div className="w-full flex justify-center items-center h-full">
            <OTPInput
              value={otp}
              onChange={setOtp}
              shouldAutoFocus
              numInputs={4}
              renderSeparator={<span className="text-transparent">-</span>}
              renderInput={({
                style: { color, background, ...style },
                ...props
              }) => (
                <Input
                  {...props}
                  style={{
                    ...style,
                    color: void 0,
                    backgroundColor: void 0,
                    width: 40,
                  }}
                  autoComplete="off"
                />
              )}
            />
          </div>
          <div className="text-center text-muted-foreground text-sm">
            {t(
              "A verification code has been sent to your mobile number and email"
            )}
          </div>
          {resendOtpShow ||
            (true && (
              <div className="text-muted-foreground pt-2 text-center">
                {t("Didn't receive OTP? ")}{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => resend()}
                >
                  {t("Resend")}
                </span>
              </div>
            ))}
          <DialogFooter>
            <Button onClick={() => submit()}>
              {t("Submit")}
              {otpLoading ? <LuLoader /> : ""}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="max-w-[700px] w-full mb-5">
        <Form {...form}>
          <form
            method="post"
            autoComplete="false"
            onSubmit={form.handleSubmit(handleSubmitForm)}
            className="flex flex-col space-y-4 "
          >
            <div className="flex w-full gap-4 ">
              <FormField
                control={form.control}
                name="firstName"
                rules={{ required: t("First name is required") }}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        className={cn(
                          "w-full",
                          !field.value && "text-muted-foreground"
                        )}
                        placeholder={t("First name *")}
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                rules={{ required: t("Last name is required") }}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        className="w-full"
                        placeholder={t("Last name *")}
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("components.loginCard.error.validEmail"),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className={"w-full"}
                      placeholder={t("Email *")}
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              rules={{ required: "Password is required" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder={t("Choose a password *")}
                        type={showPassword ? "text" : "password"}
                        {...field}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-muted-foreground cursor-pointer">
                        {showPassword ? (
                          <EyeOff
                            className="h-5 w-5"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <Eye
                            className="h-5 w-5"
                            onClick={togglePasswordVisibility}
                          />
                        )}
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              rules={{ required: "Please select one option" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="Country of residence *" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <ScrollArea className="h-48 max-h-48">
                            {countrySelectOpt?.map((item: any, index) => {
                              return (
                                <SelectItem
                                  value={item.value}
                                  key={`country-${index}`}
                                >
                                  {item.label}
                                </SelectItem>
                              );
                            })}
                          </ScrollArea>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <div className="flex w-full gap-4">
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className="w-[150px] justify-between"
                          >
                            {value ? (
                              <div className="flex gap-1 tel-input items-center">
                                <div
                                  className={`flag ${
                                    countryData?.find(
                                      (framework: any) =>
                                        framework.name.toLowerCase() ==
                                        value.toLowerCase()
                                    )?.code
                                  }`}
                                ></div>
                                <div>
                                  {
                                    countryData?.find(
                                      (framework: any) =>
                                        framework.name.toLowerCase() ==
                                        value.toLowerCase()
                                    )?.dial_code
                                  }
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[200px] p-0">
                          <Command className="tel-input">
                            <CommandInput placeholder="Search country..." />
                            <CommandEmpty>No country found.</CommandEmpty>
                            <CommandGroup>
                              <ScrollArea className="h-48">
                                {filteredData?.map((framework, id) => {
                                  const inputFlagClasses = `flag ${framework.code}`;
                                  return (
                                    <CommandItem
                                      key={id}
                                      value={framework.name}
                                      onSelect={(currentValue) => {
                                        setCountryCode(
                                          countryData?.find(
                                            (framework: any) =>
                                              framework.name.toLowerCase() ==
                                              currentValue.toLowerCase()
                                          )?.dial_code
                                        );
                                        setValue(
                                          currentValue === value
                                            ? ""
                                            : currentValue
                                        );
                                        setOpen(false);
                                      }}
                                    >
                                      <LuCheck
                                        className={cn(
                                          "mr-2 h-4 w-4",
                                          value?.toLowerCase?.() ===
                                            framework.name?.toLowerCase?.()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      <div className={inputFlagClasses} />
                                      {framework.name}{" "}
                                      <span className="ml-2 text-muted-foreground">
                                        {framework.dial_code}
                                      </span>
                                    </CommandItem>
                                  );
                                })}
                              </ScrollArea>
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone_number"
                rules={{
                  required: "Mobile number is required",
                  pattern: {
                    value:
                      /^(\+\d{1,3}[- ]?)?(\(\d{1,4}\)|\d{1,4})[- ]?\d{1,4}[- ]?\d{1,9}$/,
                    message: t("Enter a valid mobile number"),
                  },
                }}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        className="w-full"
                        placeholder={t("Mobile number *")}
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="trading"
              rules={{ required: "Please select one option" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="Trading experience *" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <ScrollArea className="h-24 max-h-24">
                            {Trading_Experience?.map((item: any, index) => {
                              return (
                                <SelectItem
                                  value={`${index}`}
                                  key={`trading-exp-${index}`}
                                >
                                  {item.label}
                                </SelectItem>
                              );
                            })}
                          </ScrollArea>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="university"
              rules={{ required: "Please select one option" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Popover
                      open={openUniversity}
                      onOpenChange={setOpenUniversity}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openUniversity}
                          className="w-full text-start px-3 justify-between"
                        >
                          {universityValue ? (
                            <div className="flex gap-1 !font-[400] items-center">
                              {
                                Universities?.find(
                                  (framework: any) =>
                                    framework.value.toLowerCase() ==
                                    universityValue.toLowerCase()
                                )?.label
                              }
                            </div>
                          ) : (
                            <span className="font-light !text-[#64748B]">
                              Choose university*
                            </span>
                          )}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-full p-0">
                        <Command>
                          <CommandInput placeholder="Search university..." />
                          <CommandEmpty>No university found.</CommandEmpty>
                          <CommandGroup>
                            <ScrollArea className="h-48 w-full">
                              {Universities?.map((university) => {
                                return (
                                  <CommandItem
                                    key={university.value}
                                    value={university.label}
                                    onChange={field.onChange}
                                    onSelect={() => {
                                      form.setValue("university", university.val as string);
                                      setUniversityValue(university.value);
                                      setOpenUniversity(false);
                                    }}
                                  >
                                    <LuCheck
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        universityValue?.toLowerCase?.() ===
                                          university.value?.toLowerCase?.()
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {university.label}{" "}
                                  </CommandItem>
                                );
                              })}
                            </ScrollArea>
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="degree"
              rules={{ required: t("Degree name is required") }}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input
                      className="w-full"
                      placeholder={t("Degree name *")}
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="yos"
              rules={{ required: "Year of study" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger className="w-full ">
                        <SelectValue placeholder="Choose year of study *" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <ScrollArea className="h-24 max-h-24">
                            {yos?.map((item: any, index) => {
                              return (
                                <SelectItem
                                  value={`${item.value}`}
                                  key={`yos-${index}`}
                                >
                                  {item.label}
                                </SelectItem>
                              );
                            })}
                          </ScrollArea>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <div className="flex gap-4 w-full justify-center text-xs text-muted-foreground">
              <div>
                Facing an issue? Email us at{" "}
                <a
                  href="mailto: contact@trylevel2.com"
                  className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
                >
                  contact@trylevel2.com
                </a>
              </div>
              <div>
                {t("Have an account already? ")}{" "}
                <a
                  href="https://app.trylevel2.com"
                  className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
                >
                  {"Sign In"}
                </a>
              </div>
            </div>

            <div className="text-center flex justify-center gap-4">
              {/* <Button
                type="button"
                variant={"outline"}
                onClick={() => router.push("/")}
              >
                {t("Cancel")}
              </Button> */}
              <Button type="submit" className=" gap-2">
                {t("Register Free")}

                {loading ? (
                  <Loader2 className="ml-2 h-5 w-5 animate-spin" />
                ) : (
                  <BsArrowRight />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

// LoginCard.getInitialProps(context) {
//     // Fetch data from external API
//     // const res = await fetch(`https://.../data`)
//     // const data = await res.json()

//     // Pass data to the page via props
//     return { props: { } }
// }

export default CreateCard;
